var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'editor-active': _vm.editor === true,
    'elevation-3': _vm.editor === true,
    'form-item-not-editor': _vm.editor === false,
    'form-item': true
  }},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-btn-toggle',{class:{
          'float-right': _vm.editor === false,
          'edit-option': _vm.editor === false,
          'edit-option-hidden': _vm.editor === true
        }},[_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.showEditor()}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"red","x-small":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"x-small":"","light":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1),(_vm.editor)?_c('v-row',{staticClass:"editor py-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-jsf',{attrs:{"schema":_vm.editorSettings},scopedSlots:_vm._u([{key:"custom-tiptap",fn:function(context){return [_c('tiptap-editor-view',_vm._b({},'tiptap-editor-view',context,false))]}}],null,false,1082051893),model:{value:(_vm.editorSettingsModel),callback:function ($$v) {_vm.editorSettingsModel=$$v},expression:"editorSettingsModel"}})],1),_c('v-row',{staticClass:"float-right my-2 py-1 actions"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.done()}}},[_vm._v(" done ")])],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Key","dense":"","outlined":""},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)],1)],1):_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-jsf',{attrs:{"schema":_vm.element.schema},scopedSlots:_vm._u([{key:"custom-tiptap",fn:function(context){return [_c('tiptap-renderer-view',_vm._b({},'tiptap-renderer-view',context,false))]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }